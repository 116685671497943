import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { DeviceTypes, AlertTypes, AlertTrigger } from 'constants/index';
import InputSearch from 'components/InputSearch';
import H5 from 'components/H5';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import PaginationButtons from 'components/PaginationButtons';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import ListEmpty from 'components/ListEmpty';
import moment from 'moment';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding-top: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

export default class AlertListView extends Component {
  state = {
    search: '',
    device_type: '',
    status: '',
    brand_id: '',
    account_id: '',
    state: '',
    city: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    if (name === 'brand_id') {
      const { fetchAccounts } = this.props;
      fetchAccounts({ brands: [value] });
    }

    if (name === 'state') {
      const { fetchCities } = this.props;
      fetchCities(value);
    }

    this.setState({ [name]: value }, () => {
      const { fetchAlerts } = this.props;
      const { search, device_type, status, brand_id, account_id, state, city } = this.state;

      fetchAlerts({ search, device_type, status, brand_id, account_id, state, city });
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchAlerts } = this.props;
      const { search, device_type, status, brand_id, account_id, state, city } = this.state;

      fetchAlerts({ search, device_type, status, brand_id, account_id, state, city });
    });
  };

  renderItem = (item, key) => {
    const iconColor =
      item.type === 'warning' ? Colors.red : item.type === 'alert' ? Colors.orange : Colors.green;
    const iconName = item.type === 'success' ? 'check-circle' : 'exclamation-circle';

    const getTriggerLabel = () =>
      AlertTrigger[item.AlertType.device_type].find(el => el.value === item.AlertType.trigger)
        .label;

    const getDeviceType = () => {
      switch (item.AlertType.device_type) {
        case 'water-softener':
          return 'Softener';
        case 'water-filter':
          return 'Water Filter';
        case 'reverse-osmosis':
          return 'Reverse Osmosis';
      }
    };

    const { markAlertAsViewed } = this.props;

    return (
      <TRow key={key}>
        <TableItem width="20%">
          <H5 style={{ lineHeight: '0px' }}>
            <Icon name={iconName} color={iconColor} customStyle={{ marginRight: '10px' }} />
            {item.title}
          </H5>
        </TableItem>
        <TableItem width="12%">{item.Location.identificator}</TableItem>
        <TableItem width="12%">{getDeviceType()}</TableItem>
        <TableItem width="12%">{getTriggerLabel()}</TableItem>
        <TableItem width="12%">{item.Location.account_name}</TableItem>
        <TableItem width="12%">{moment(item.created_at).format('MM/DD/YYYY hh:mm a')}</TableItem>
        <TableItem width="20%">
          <H3
            onClick={() => markAlertAsViewed({ alert_id: item.id })}
            style={{ cursor: 'pointer' }}
            weight="bold"
            color={Colors.lightBlue}
            textAlign="center"
          >
            Mark as viewed
          </H3>
        </TableItem>
      </TRow>
    );
  };

  render() {
    const {
      list,
      loading,
      states,
      cities,
      brands,
      accounts,
      pages,
      currentPage,
      fetchAlerts,
    } = this.props;
    const { search, device_type, status, brand_id, account_id, state, city } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Device Type:"
              selectedValue={device_type}
              name="device_type"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...DeviceTypes]}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Status:"
              selectedValue={status}
              name="status"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...AlertTypes]}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Brand:"
              selectedValue={brand_id}
              name="brand_id"
              handleChange={this.filterOnChange}
              options={brands}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Account:"
              selectedValue={account_id}
              name="account_id"
              handleChange={this.filterOnChange}
              options={accounts}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="State:"
              selectedValue={state}
              name="state"
              handleChange={this.filterOnChange}
              options={states}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="City:"
              selectedValue={city}
              name="city"
              handleChange={this.filterOnChange}
              options={cities}
            />
          </FilterContainer>
        </FilterGroup>

        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="20%">Alert</TableTitle>
              <TableTitle width="12%">Location Id</TableTitle>
              <TableTitle width="12%">Device</TableTitle>
              <TableTitle width="12%">Trigger</TableTitle>
              <TableTitle width="12%">Account</TableTitle>
              <TableTitle width="12%">Date</TableTitle>
              <TableTitle width="20%">Action</TableTitle>
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no alerts" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page =>
              fetchAlerts({ page, search, device_type, status, brand_id, account_id, state, city })
            }
            totalPages={pages}
          />
        </Footer>
      </Container>
    );
  }
}

AlertListView.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  cities: PropTypes.array,

  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchAlerts: PropTypes.func,
  fetchCities: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  markAlertAsViewed: PropTypes.func,
  pages: PropTypes.number,
  states: PropTypes.array,
};
