// @flow
/**
 * @module Actions/Brand
 * @desc Brand Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getBrands,
  getBrand,
  getBrandsForFilter,
  createBrand,
  editBrand,
  deleteBrand,
} = createActions({
  [ActionTypes.GET_BRANDS]: params => params,
  [ActionTypes.GET_BRAND]: id => ({ id }),
  [ActionTypes.GET_BRANDS_FOR_FILTER]: () => ({}),
  [ActionTypes.CREATE_BRAND]: data => data,
  [ActionTypes.EDIT_BRAND]: (id, brand) => ({ id, brand }),
  [ActionTypes.DELETE_BRAND]: id => ({ id }),
});
