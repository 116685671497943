import { put, call, delay } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* login(api, action) {
  const {
    payload: { email, password },
  } = action;
  const response = yield call(api.localLogin, { email, password });
  const { status, data } = response;

  if (status === 200) {
    const { token, profile } = data;
    yield call(api.setAuthorization, token);
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: { profile, token },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
    });
  }
}

export function* recoverPassword(api, action) {
  const {
    payload: { email },
  } = action;

  const response = yield call(api.recoverPasswordRequest, email);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('E-mail sent!', { variant: 'success' }));
  } else {
    const { errors } = data;

    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
export function* resetPassword(api, action) {
  const {
    payload: { token_pass_recovery, password },
  } = action;
  const response = yield call(api.resetPasswordRequest, { token_pass_recovery, password });
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Your password has been reset!', { variant: 'success' }));
    replace('/login');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* newPassword(api, action) {
  const {
    payload: { token_validate, password },
  } = action;
  const response = yield call(api.newPasswordRequest, { token_validate, password });
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Registration completed!', { variant: 'success' }));
    replace('/login');
  } else {
    const { errors } = data;
    if (errors.token_expired) {
      yield put({
        type: ActionTypes.UPDATE_INVITATION_TOKEN_EXPIRED,
        payload: { token_expired: true },
      });
    }

    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* logout(api) {
  yield call(api.removeAuthToken);
  yield put(showAlert('Logout successful', { variant: 'success' }));
  yield put({
    type: ActionTypes.USER_LOGOUT_SUCCESS,
  });
}

export function* updateProfile(api, action) {
  const { status, data } = yield call(api.updateProfile, action.payload.data);
  if (status === 200) {
    const { profile, requireLogout } = data;
    yield put({
      type: ActionTypes.UPDATE_PROFILE_SUCCESS,
      payload: { profile },
    });

    if (requireLogout) {
      yield put({
        type: ActionTypes.REQUIRE_LOGOUT,
      });
      yield delay(5000);
      yield call(api.removeAuthToken);
      yield put(showAlert('Logout successful', { variant: 'success' }));
      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
      });
    } else {
      yield put(showAlert('Profile Updated.', { variant: 'success' }));
    }
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* changePassword(api, action) {
  const { status, data } = yield call(api.changePassword, action.payload.data);
  if (status === 200) {
    yield put({
      type: ActionTypes.REQUIRE_LOGOUT,
    });
    yield delay(5000);
    yield call(api.removeAuthToken);
    yield put(showAlert('Password updated.', { variant: 'success' }));
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* closeAccount(api, action) {
  const { status, data } = yield call(api.closeAccount, action.payload.data);
  if (status === 200) {
    yield put({
      type: ActionTypes.REQUIRE_LOGOUT,
    });
    yield delay(5000);
    yield call(api.removeAuthToken);
    yield put(showAlert('Account Deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
export function* resendInvitation(api, action) {
  const { status, data } = yield call(api.resendInvitation, action.payload);
  if (status === 200) {
    yield put(showAlert('Invitation resubmitted, check your email', { variant: 'success' }));
    replace('/login');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
