import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  createUser,
  getRoles,
  getBrandsForFilter,
  getAccountsForFilter,
  getLocationsForFilter,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import PermissionsForm from './PermissionsForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class CreateUser extends Component {
  state = {
    activeTab: 'Details',
    user: {
      brands: [],
      accounts: [],
      locations: [],
    },
  };

  componentDidMount() {
    const { fetchRoles, fetchBrands } = this.props;

    fetchRoles();
    fetchBrands();
  }

  saveData = (data, nextTab) => {
    const { user, activeTab } = this.state;
    const userUpdated = {
      ...user,
      ...data,
    };

    if (activeTab === 'Permissions') {
      const { submitUser } = this.props;
      submitUser(userUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        user: userUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  render() {
    const { roles, brands, accounts, locations, fetchAccounts, fetchLocations } = this.props;

    const { activeTab, user } = this.state;

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Permissions',
      },
    ];

    const detailsInitialValues = {
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
    };

    const permissionsInitialValues = {
      role_id: '',
    };

    return (
      <Container>
        <Icon onClick={() => push('/users')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Add new user
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            isInitialValid={false}
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Permissions' && (
          <PermissionsForm
            initialValues={permissionsInitialValues}
            isInitialValid={false}
            roles={roles}
            user={user}
            brands={brands}
            accounts={accounts}
            locations={locations}
            fetchAccounts={fetchAccounts}
            fetchLocations={fetchLocations}
            back={() => this.back('Details')}
            onSubmit={this.saveData}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.utils.roles,
    brands: state.brands.listForFilter,
    accounts: state.accounts.listForFilter,
    locations: state.locations.listForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitUser: data => dispatch(createUser(data)),
    fetchRoles: () => dispatch(getRoles()),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchLocations: params => dispatch(getLocationsForFilter(params)),
  };
}

CreateUser.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchLocations: PropTypes.func,
  fetchRoles: PropTypes.func,
  locations: PropTypes.array,
  roles: PropTypes.array,
  submitUser: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
