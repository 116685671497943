import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

export default class AccountListView extends Component {
  state = {
    dropdownOpenId: null,
    accountSelected: null,
    isOpenAlert: false,
    search: '',
    brand_id: '',
    state: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      const { fetchAccounts } = this.props;
      const { brand_id, state, search } = this.state;

      fetchAccounts({ search, brand_id, state });
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchAccounts } = this.props;
      const { brand_id, state, search } = this.state;

      fetchAccounts({ search, brand_id, state });
    });
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchAlert = (accountSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, accountSelected });
  };

  editAccount = ({ id }) => {
    push(`/accounts/${id}/edit`);
  };

  remove = () => {
    const { removeAccount } = this.props;
    const {
      accountSelected: { id },
    } = this.state;

    removeAccount(id);
    this.switchAlert();
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="15%">{item.name}</TableItem>
      <TableItem width="15%">{item.brand_name}</TableItem>
      <TableItem width="20%">{item.full_address}</TableItem>
      <TableItem width="15%">{item.phone_number}</TableItem>
      <TableItem width="15%">{`${item.contact_first_name} ${item.contact_last_name}`}</TableItem>
      <TableItem width="15%">{item.amount_locations}</TableItem>
      <TableItem width="5%" isComponent>
        <Dropdown
          icon="ellipsis-v"
          options={[
            {
              title: 'Edit',
              icon: 'pencil-alt',
              color: Colors.green,
              action: () => this.editAccount(item),
            },
            {
              title: 'Remove',
              icon: 'trash',
              color: Colors.red,
              action: () => this.switchAlert(item),
            },
          ]}
          isDropdownOpen={this.state.dropdownOpenId === key}
          onClickDropdownButton={() => this.switchDropdown(key)}
        />
      </TableItem>
    </TRow>
  );

  renderAlertConfirm = () => {
    const { isOpenAlert, accountSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove account"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that want remove the "${
            accountSelected ? accountSelected.name : ''
          }" account`}
        </H4>
      </AlertConfirm>
    );
  };

  render() {
    const { list, currentPage, pages, loading, fetchAccounts, brands, states } = this.props;
    const { brand_id, state, search } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          <ButtonContainer>
            <ButtonGroup>
              <ButtonText
                theme="primary"
                text="New"
                icon="plus"
                onClick={() => push('/accounts/create')}
              />
            </ButtonGroup>
          </ButtonContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Brand:"
              selectedValue={brand_id}
              name="brand_id"
              handleChange={this.filterOnChange}
              options={brands}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="State:"
              selectedValue={state}
              name="state"
              handleChange={this.filterOnChange}
              options={states}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="15%">Name</TableTitle>
              <TableTitle width="15%">Brand</TableTitle>
              <TableTitle width="20%">Address</TableTitle>
              <TableTitle width="15%">Phone #</TableTitle>
              <TableTitle width="15%">Admin</TableTitle>
              <TableTitle width="15%">Locations</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no accounts" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchAccounts({ page, brand_id, state, search })}
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

AccountListView.propTypes = {
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeAccount: PropTypes.func,
  states: PropTypes.array,
};
