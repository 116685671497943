import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getUsers(api, action) {
  const response = yield call(api.getUsers, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_USERS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_USERS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getUser(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getUser, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_USER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_USER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getInstallersByAccount(api, action) {
  const {
    payload: { accountId },
  } = action;
  const { status, data } = yield call(api.getInstallersByAccount, accountId);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_INSTALLERS_BY_ACCOUNT_SUCCESS,
      payload: { installers: data },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_INSTALLERS_BY_ACCOUNT_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createUser(api, action) {
  const response = yield call(api.createUser, action.payload);

  const { status } = response;

  if (status === 200) {
    yield put(showAlert('User created', { variant: 'success' }));
    replace('/users');
  } else if (status !== 500 && status !== 401) {
    yield put(showAlert('Email in use', { variant: 'danger' }));
  }

  yield put({
    type: ActionTypes.CREATE_USER_RESOLVED,
  });
}

export function* editUser(api, action) {
  const { id, user } = action.payload;
  const response = yield call(api.editUser, id, user);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('User edited', { variant: 'success' }));
    replace('/users');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.EDIT_USER_RESOLVED,
  });
}

export function* deleteUser(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteUser, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('User deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_USER_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.DELETE_USER_FAILURE,
      payload: { id },
    });
  }
}

export function* resendEmail(api, action) {
  const { id } = action.payload;

  const response = yield call(api.resendEmail, id);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Invitation resubmitted', { variant: 'success' }));
    yield put({
      type: ActionTypes.RESEND_EMAIL_SUCCESS,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.RESEND_EMAIL_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
