import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';

export function* getAlerts(api, action) {
  const response = yield call(api.getAlerts, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_ALERTS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALERTS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getAllAlerts(api, action) {
  const response = yield call(api.getAllAlerts, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_ALL_ALERTS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALL_ALERTS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* markAlertAsViewed(api, action) {
  const { location_id } = action.payload;
  delete action.payload.location_id;
  const response = yield call(api.markAlertAsViewed, action.payload);
  const { status } = response;
  // errors

  if (status === 200) {
    const payload = {};
    if (location_id) {
      payload.location_id = location_id;
    }

    yield put({
      type: ActionTypes.GET_ALERTS,
      payload,
    });

    yield put({
      type: ActionTypes.MARK_ALERT_AS_VIEWED_SUCCESS,
    });
  } else {
    yield put({
      type: ActionTypes.MARK_ALERT_AS_VIEWED_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      const {
        data: { errors },
      } = response;

      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
