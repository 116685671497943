import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Textarea from 'components/Textarea';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const Section = styled.div`
  margin-top: 25px;
`;

export default class ConfigurationForm extends Component {
  state = {
    waterFilterHeads: [],
    reverseOsmosisFilterHeads: [],
    showWaterFilter: false,
    showReverseOsmosis: false,
    showWaterSoftener: false,
  };

  componentDidMount() {
    const { initialValues } = this.props;
    const { reverseOsmosis, waterFilter, waterSoftener } = initialValues;
    if (waterFilter.water_filter_config_id) {
      let index = 0;
      const configurations = this.props.waterFilterConfigurationsForFilter;
      const currentConfig = configurations.find(
        config => config.value === waterFilter.water_filter_config_id,
      );

      const waterFilterHeads = { ...currentConfig.waterFilters };

      waterFilterHeads.flowMeters = waterFilterHeads.SpotsWaterFilters.map(
        ({ id, Catridge, position }) => ({
          spot_water_filter_head_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );

      this.setState({
        waterFilterHeads,
        showWaterFilter: true,
      });
    }

    if (reverseOsmosis.ro_filter_config_id) {
      let index = 0;
      const configurations = this.props.reverseOsmosisConfigurationsForFilter;
      const currentConfig = configurations.find(
        config => config.value === reverseOsmosis.ro_filter_config_id,
      );

      const reverseOsmosisFilterHeads = { ...currentConfig.reverseOsmosisFilters };

      reverseOsmosisFilterHeads.flowMeters = reverseOsmosisFilterHeads.SpotsReverseOsmosisFilters.map(
        ({ id, Catridge, position }) => ({
          spot_reverse_osmosis_filter_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );
      this.setState({ reverseOsmosisFilterHeads, showReverseOsmosis: true });
    }
    if (waterSoftener.softener_configuration_id) {
      this.setState({ showWaterSoftener: true });
    }
  }

  submit = values => {
    const { onSubmit } = this.props;
    const nextTab = 'Installer';
    onSubmit({ ...values }, nextTab);
  };

  backStep = values => {
    const { onSubmit } = this.props;
    const nextTab = 'Details';
    onSubmit({ ...values }, nextTab);
  };

  getBrineDrumId = evt => {
    const { softenerConfigurationsForFilter } = this.props;
    const value = parseInt(evt.target.value, 10);

    const currentConfig = softenerConfigurationsForFilter.find(config => config.value === value);

    return currentConfig ? currentConfig.softener.brine_drum_id : null;
  };

  setFlowMeters = (evt, field, configuration) => {
    const value = parseInt(evt.target.value, 10);
    const configurations = this.props[configuration];

    const currentConfig = configurations.find(config => config.value === value);

    this.setState({
      [field]: currentConfig,
    });

    if (field === 'waterFilterHeads') {
      let index = 0;
      const waterFilterHeads = { ...currentConfig.waterFilters };

      waterFilterHeads.flowMeters = waterFilterHeads.SpotsWaterFilters.map(
        ({ id, Catridge, position }) => ({
          spot_water_filter_head_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );

      this.setState({ waterFilterHeads });

      return waterFilterHeads.flowMeters.map(({ spot_water_filter_head_id, type, name }) => ({
        spot_water_filter_head_id,
        type,
        name,
      }));
    }

    if (field === 'reverseOsmosisFilterHeads') {
      let index = 0;
      const reverseOsmosisFilterHeads = { ...currentConfig.reverseOsmosisFilters };
      reverseOsmosisFilterHeads.flowMeters = reverseOsmosisFilterHeads.SpotsReverseOsmosisFilters.map(
        ({ id, Catridge, position }) => ({
          spot_reverse_osmosis_filter_id: id,
          position,
          catridge_name: Catridge.name,
          chlorine_capacity: Catridge.chlorine_capacity,
          chloramine_capacity: Catridge.chloramine_capacity,
          type: '',
          name: '',
          key: index++,
        }),
      );

      this.setState({ reverseOsmosisFilterHeads });

      return reverseOsmosisFilterHeads.flowMeters.map(
        ({ spot_reverse_osmosis_filter_id, type, name }) => ({
          spot_reverse_osmosis_filter_id,
          type,
          name,
        }),
      );
    }
    return [];
  };

  renderWaterFilterHeads = (
    handleChange,
    { flowMeterOfWaterFilters, chlorine_or_chloramine },
    errors = {},
  ) => {
    const { waterFilterHeads } = this.state;
    const { waterFilterDisabled, reverseOsmosisDisabled, softenerDisabled } = this.props;

    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesWf =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};
    return (
      <Container>
        {waterFilterHeads.flowMeters.map(
          ({ position, catridge_name, chlorine_capacity, chloramine_capacity, key }, index) => (
            <Container key={index}>
              <Row>
                <Column style={{ paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    {`${position} - ${catridge_name}`}
                  </H4>
                </Column>
                <Column>
                  <H4 inputLabel color={Colors.black}>
                    {`Capacity : ${
                      chlorine_or_chloramine === 'Chlorine'
                        ? chlorine_capacity
                        : chloramine_capacity
                    } gal.`}
                  </H4>
                </Column>
              </Row>

              <Row>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Type
                  </H4>
                  <InputContainer>
                    <Select
                      placeholder="Type"
                      name={`waterFilter.flowMeterOfWaterFilters.${key}.type`}
                      value={flowMeterOfWaterFilters[key].type}
                      options={[{ label: 'N/A', value: 'N/A' }]}
                      onChange={handleChange}
                      style={disabledStylesWf}
                    />

                    <FormError
                      text={
                        errors.flowMeterOfWaterFilters &&
                        errors.flowMeterOfWaterFilters[key] &&
                        errors.flowMeterOfWaterFilters[key].type &&
                        errors.flowMeterOfWaterFilters[key].type
                      }
                    />
                  </InputContainer>
                </Column>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Name
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Name"
                      name={`waterFilter.flowMeterOfWaterFilters.${key}.name`}
                      value={flowMeterOfWaterFilters[key].name}
                      onChange={handleChange}
                      style={disabledStylesWf}
                    />
                    <FormError
                      text={
                        errors.flowMeterOfWaterFilters &&
                        errors.flowMeterOfWaterFilters[key] &&
                        errors.flowMeterOfWaterFilters[key].name &&
                        errors.flowMeterOfWaterFilters[key].name
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>
            </Container>
          ),
        )}
      </Container>
    );
  };

  renderReverseOsmosisFilterHeads = (
    handleChange,
    { flowMeterOfROFilters, chlorine_or_chloramine },
    errors = {},
  ) => {
    const { reverseOsmosisFilterHeads } = this.state;
    const { waterFilterDisabled, reverseOsmosisDisabled, softenerDisabled } = this.props;

    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesRo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};

    return (
      <Container>
        {reverseOsmosisFilterHeads.flowMeters.map(
          ({ position, catridge_name, chlorine_capacity, chloramine_capacity, key }, index) => (
            <Container key={index}>
              <Row>
                <Column style={{ paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    {`${position} - ${catridge_name}`}
                  </H4>
                </Column>
                <Column>
                  <H4 inputLabel color={Colors.black}>
                    {`Capacity : ${
                      chlorine_or_chloramine === 'Chlorine'
                        ? chlorine_capacity
                        : chloramine_capacity
                    } gal.`}
                  </H4>
                </Column>
              </Row>

              <Row>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Type
                  </H4>
                  <InputContainer>
                    <Select
                      placeholder="Type"
                      name={`reverseOsmosis.flowMeterOfROFilters.${key}.type`}
                      value={flowMeterOfROFilters[key].type}
                      options={[{ label: 'N/A', value: 'N/A' }]}
                      onChange={handleChange}
                      style={disabledStylesRo}
                    />

                    <FormError
                      text={
                        errors.flowMeterOfROFilters &&
                        errors.flowMeterOfROFilters[key] &&
                        errors.flowMeterOfROFilters[key].type &&
                        errors.flowMeterOfROFilters[key].type
                      }
                    />
                  </InputContainer>
                </Column>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Name
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Name"
                      name={`reverseOsmosis.flowMeterOfROFilters.${key}.name`}
                      value={flowMeterOfROFilters[key].name}
                      onChange={handleChange}
                      style={disabledStylesRo}
                    />
                    <FormError
                      text={
                        errors.flowMeterOfROFilters &&
                        errors.flowMeterOfROFilters[key] &&
                        errors.flowMeterOfROFilters[key].name &&
                        errors.flowMeterOfROFilters[key].name
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>
            </Container>
          ),
        )}
      </Container>
    );
  };

  render() {
    const {
      initialValues,
      waterFilterConfigurationsForFilter,
      reverseOsmosisConfigurationsForFilter,
      softenerConfigurationsForFilter,
      waterFilterDisabled,
      reverseOsmosisDisabled,
      softenerDisabled,
    } = this.props;
    const { showWaterFilter, showWaterSoftener, showReverseOsmosis } = this.state;
    const disabledStyle = { pointerEvents: 'none', opacity: 0.5 };
    const disabledStylesWf =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};
    const disabledStylesRo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};
    const disabledStylesSo =
      waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled ? disabledStyle : {};

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          reverseOsmosis:
            showReverseOsmosis &&
            Yup.object().shape({
              chlorine_or_chloramine: Yup.string().required('Required'),
              flowMeterOfROFilters: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Required'),
                  spot_reverse_osmosis_filter_id: Yup.string().required('Required'),
                  type: Yup.string().required('Required'),
                }),
              ),
              low_inlet_pressure: Yup.number().required('Required'),
              ro_filter_config_id: Yup.string().required('Required'),
            }),
          waterFilter:
            showWaterFilter &&
            Yup.object().shape({
              chlorine_or_chloramine: Yup.string().required('Required'),
              flowMeterOfWaterFilters: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Required'),
                  spot_water_filter_head_id: Yup.string().required('Required'),
                  type: Yup.string().required('Required'),
                }),
              ),
              water_filter_config_id: Yup.string().required('Required'),
            }),
          waterSoftener:
            showWaterSoftener &&
            Yup.object().shape({
              description: Yup.string().required('Required'),
              softener_configuration_id: Yup.string().required('Required'),
            }),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
          const resetWaterFilter = () => {
            this.setState({ showWaterFilter: false });
            setFieldValue('waterFilter.water_filter_config_id', '');
            setFieldValue('waterFilter.chlorine_or_chloramine', 'Chlorine');
            setFieldValue('waterFilter.flowMeterOfWaterFilters', []);
          };

          const resetReverseOsmosis = () => {
            this.setState({ showReverseOsmosis: false });
            setFieldValue('reverseOsmosis.ro_filter_config_id', '');
            setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chlorine');
            setFieldValue('reverseOsmosis.flowMeterOfROFilters', []);
          };
          const resetSoftener = () => {
            this.setState({ showWaterSoftener: false });
            setFieldValue('waterSoftener.softener_configuration_id', '');
            setFieldValue('waterSoftener.description', '');
            setFieldValue('waterSoftener.brine_drum_id', null);
          };
          return (
            <Form style={{ margin: '30px' }}>
              {showWaterFilter ? (
                <ButtonText
                  text="Remove Water filter"
                  type="button"
                  theme="primary"
                  onClick={resetWaterFilter}
                  customStyles={disabledStylesWf}
                />
              ) : (
                <ButtonText
                  text="Add Water filter"
                  type="button"
                  theme="primary"
                  onClick={() => this.setState({ showWaterFilter: true })}
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? { ...disabledStyle, marginTop: 25 }
                      : { marginTop: 25 }
                  }
                />
              )}

              {showWaterFilter && (
                <Section>
                  <Row>
                    <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        Configuration type
                      </H4>
                      <InputContainer>
                        <Select
                          placeholder="Configuration type"
                          name="waterFilter.water_filter_config_id"
                          options={waterFilterConfigurationsForFilter}
                          value={values.waterFilter.water_filter_config_id}
                          style={disabledStylesWf}
                          onChange={evt => {
                            setFieldValue(
                              'waterFilter.flowMeterOfWaterFilters',
                              this.setFlowMeters(
                                evt,
                                'waterFilterHeads',
                                'waterFilterConfigurationsForFilter',
                              ),
                            );
                            handleChange(evt);
                          }}
                        />

                        <FormError
                          text={
                            errors.waterFilter &&
                            errors.waterFilter.water_filter_config_id &&
                            touched.waterFilter &&
                            touched.waterFilter.water_filter_config_id &&
                            errors.waterFilter.water_filter_config_id
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>

                  <Row>
                    <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                      <InputContainer>
                        <ButtonText
                          text="Chlorine"
                          type="button"
                          theme="text"
                          icon={
                            values.waterFilter.chlorine_or_chloramine === 'Chlorine'
                              ? 'check-circle'
                              : 'circle'
                          }
                          customStyles={{
                            marginTop: '20px',
                            marginRight: '20px',
                            ...disabledStylesWf,
                          }}
                          onClick={() =>
                            setFieldValue('waterFilter.chlorine_or_chloramine', 'Chlorine')
                          }
                        />
                        <ButtonText
                          text="Chloramine"
                          type="button"
                          theme="text"
                          icon={
                            values.waterFilter.chlorine_or_chloramine === 'Chloramine'
                              ? 'check-circle'
                              : 'circle'
                          }
                          customStyles={{
                            marginTop: '20px',
                            marginRight: '20px',
                            ...disabledStylesWf,
                          }}
                          onClick={() =>
                            setFieldValue('waterFilter.chlorine_or_chloramine', 'Chloramine')
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>
                  {values.waterFilter.flowMeterOfWaterFilters.length > 0 &&
                    this.renderWaterFilterHeads(
                      handleChange,
                      values.waterFilter,
                      errors.waterFilter,
                    )}
                </Section>
              )}
              <Line />

              {showReverseOsmosis ? (
                <ButtonText
                  text="Remove Reverse Osmosis"
                  type="button"
                  theme="primary"
                  onClick={resetReverseOsmosis}
                  customStyles={disabledStylesRo}
                />
              ) : (
                <ButtonText
                  text="Add Reverse Osmosis"
                  type="button"
                  theme="primary"
                  onClick={() => this.setState({ showReverseOsmosis: true })}
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? { ...disabledStyle, marginTop: 25 }
                      : { marginTop: 25 }
                  }
                />
              )}

              {showReverseOsmosis && (
                <Section>
                  <Row>
                    <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        Configuration type
                      </H4>
                      <InputContainer>
                        <Select
                          placeholder="Configuration type"
                          name="reverseOsmosis.ro_filter_config_id"
                          options={reverseOsmosisConfigurationsForFilter}
                          value={values.reverseOsmosis.ro_filter_config_id}
                          onChange={evt => {
                            setFieldValue(
                              'reverseOsmosis.flowMeterOfROFilters',
                              this.setFlowMeters(
                                evt,
                                'reverseOsmosisFilterHeads',
                                'reverseOsmosisConfigurationsForFilter',
                              ),
                            );
                            handleChange(evt);
                          }}
                          style={disabledStylesRo}
                        />
                        <FormError
                          text={
                            errors.reverseOsmosis &&
                            errors.reverseOsmosis.ro_filter_config_id &&
                            touched.reverseOsmosis &&
                            touched.reverseOsmosis.ro_filter_config_id &&
                            errors.reverseOsmosis.ro_filter_config_id
                          }
                        />
                      </InputContainer>
                    </Column>
                    <Column style={{ marginRight: '30px', width: '33.33%' }}>
                      <InputContainer>
                        <H4 inputLabel color={Colors.gray8}>
                          Low inlet pressure
                        </H4>
                        <Input
                          placeholder="Low inlet pressure"
                          name="reverseOsmosis.low_inlet_pressure"
                          type="number"
                          value={values.reverseOsmosis.low_inlet_pressure}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <FormError
                          text={
                            errors.reverseOsmosis &&
                            errors.reverseOsmosis.low_inlet_pressure &&
                            touched.reverseOsmosis &&
                            touched.reverseOsmosis.low_inlet_pressure &&
                            errors.reverseOsmosis.low_inlet_pressure
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>
                  <Row>
                    <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                      <InputContainer>
                        <ButtonText
                          text="Chlorine"
                          type="button"
                          theme="text"
                          icon={
                            values.reverseOsmosis.chlorine_or_chloramine === 'Chlorine'
                              ? 'check-circle'
                              : 'circle'
                          }
                          customStyles={{
                            marginTop: '20px',
                            marginRight: '20px',
                            ...disabledStylesRo,
                          }}
                          onClick={() =>
                            setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chlorine')
                          }
                        />
                        <ButtonText
                          text="Chloramine"
                          type="button"
                          theme="text"
                          icon={
                            values.reverseOsmosis.chlorine_or_chloramine === 'Chloramine'
                              ? 'check-circle'
                              : 'circle'
                          }
                          customStyles={{
                            marginTop: '20px',
                            marginRight: '20px',
                            ...disabledStylesRo,
                          }}
                          onClick={() =>
                            setFieldValue('reverseOsmosis.chlorine_or_chloramine', 'Chloramine')
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>
                  {values.reverseOsmosis.flowMeterOfROFilters.length > 0 &&
                    this.renderReverseOsmosisFilterHeads(
                      handleChange,
                      values.reverseOsmosis,
                      errors.reverseOsmosis,
                    )}
                </Section>
              )}
              <Line />

              {showWaterSoftener ? (
                <ButtonText
                  text="Remove Water Softener"
                  type="button"
                  theme="primary"
                  onClick={resetSoftener}
                  customStyles={disabledStylesSo}
                />
              ) : (
                <ButtonText
                  text="Add Water Softener"
                  type="button"
                  theme="primary"
                  onClick={() => this.setState({ showWaterSoftener: true })}
                  customStyles={
                    waterFilterDisabled || reverseOsmosisDisabled || softenerDisabled
                      ? { ...disabledStyle, marginTop: 25 }
                      : { marginTop: 25 }
                  }
                />
              )}
              {showWaterSoftener && (
                <Section>
                  <Row>
                    <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        Configuration type
                      </H4>
                      <InputContainer>
                        <Select
                          placeholder="Configuration type"
                          name="waterSoftener.softener_configuration_id"
                          options={softenerConfigurationsForFilter}
                          value={values.waterSoftener.softener_configuration_id}
                          onChange={evt => {
                            handleChange(evt);
                            setFieldValue('waterSoftener.brine_drum_id', this.getBrineDrumId(evt));
                          }}
                          style={disabledStylesSo}
                        />

                        <FormError
                          text={
                            errors.waterSoftener &&
                            errors.waterSoftener.softener_configuration_id &&
                            touched.waterSoftener &&
                            touched.waterSoftener.softener_configuration_id &&
                            errors.waterSoftener.softener_configuration_id
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>
                  <Row>
                    <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        Description
                      </H4>
                      <InputContainer>
                        <Textarea
                          value={values.waterSoftener.description}
                          onChange={value => setFieldValue('waterSoftener.description', value)}
                          higlights={[]}
                          onBlur={handleBlur}
                          customStyles={disabledStylesSo}
                        />

                        <FormError
                          text={
                            errors.waterSoftener &&
                            errors.waterSoftener.description &&
                            touched.waterSoftener &&
                            touched.waterSoftener.description &&
                            errors.waterSoftener.description
                          }
                        />
                      </InputContainer>
                    </Column>
                  </Row>
                </Section>
              )}
              <Line />
              <Row>
                <ButtonContainer>
                  <ButtonText
                    text="Back"
                    type="button"
                    theme="default"
                    customStyles={{ marginTop: '20px', marginRight: '20px' }}
                    onClick={() => this.backStep(values)}
                  />
                  <ButtonText text="Next" theme="primary" type="submit" />
                </ButtonContainer>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

ConfigurationForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  reverseOsmosisConfigurationsForFilter: PropTypes.array,
  reverseOsmosisDisabled: PropTypes.bool,
  softenerConfigurationsForFilter: PropTypes.array,
  softenerDisabled: PropTypes.bool,
  waterFilterConfigurationsForFilter: PropTypes.array,
  waterFilterDisabled: PropTypes.bool,
};
