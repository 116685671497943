import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getAlertsTypes(api, action) {
  const response = yield call(api.getAlertsTypes, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_ALERTS_TYPES_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALERTS_TYPES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getAlertType(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getAlertType, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ALERT_TYPE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALERT_TYPE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createAlertType(api, action) {
  const response = yield call(api.createAlertType, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Alert type created', { variant: 'success' }));
    replace('/alerts');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_ALERT_TYPE_RESOLVED,
  });
}

export function* editAlertType(api, action) {
  const { id, alertType } = action.payload;
  const response = yield call(api.editAlertType, id, alertType);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Alert type edited', { variant: 'success' }));
    replace('/alerts');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.EDIT_ALERT_TYPE_RESOLVED,
  });
}

export function* deleteAlertType(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteAlertType, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Alert type deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_ALERT_TYPE_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.DELETE_ALERT_TYPE_FAILURE,
      payload: { id },
    });
  }
}
