import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  createLocation,
  getStates,
  getCitiesByState,
  getBrandsForFilter,
  getAccountsForFilter,
  getInstallersByAccount,
  getWaterFilterConfigurationsForFilter,
  getReverseOsmosisConfigurationsForFilter,
  getSoftenerConfigurationsForFilter,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import ConfigurationForm from './ConfigurationForm';
import InstallerForm from './InstallerForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class CreateLocation extends Component {
  state = {
    activeTab: 'Details',
    location: {
      identificator: '',
      account_id: '',
      address_line: '',
      address_line_1: '',
      brand_id: '',
      city_id: '',
      contact_email: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone_number: '',
      contact_position: '',

      state_id: '',
      zip_code: '',
      waterFilter: {
        water_filter_config_id: '',
        chlorine_or_chloramine: 'Chlorine',
        flowMeterOfWaterFilters: [],
      },
      reverseOsmosis: {
        ro_filter_config_id: '',
        chlorine_or_chloramine: 'Chlorine',
        flowMeterOfROFilters: [],
      },
      waterSoftener: {
        softener_configuration_id: '',
        description: '',
      },
      installer_first_name: '',
      installer_last_name: '',
      installer_email: '',
      installer_phone_number: '',
      installer_company_name: '',
      estimated_install_date_from: new Date(),
      estimated_install_date_to: new Date(),
    },
  };

  componentDidMount() {
    const {
      fetchStates,
      fetchBrands,
      fetchWaterFilterConfigurations,
      fetchReverseOsmosisConfigurations,
      fetchSoftenerConfigurations,
    } = this.props;

    fetchStates();
    fetchBrands();
    fetchWaterFilterConfigurations();
    fetchReverseOsmosisConfigurations();
    fetchSoftenerConfigurations();
  }

  saveData = (data, nextTab, persistLocation = false) => {
    const { location /* activeTab */ } = this.state;
    const locationUpdated = {
      ...location,
      ...data,
    };

    if (nextTab === 'Installer') {
      const { fetchInstallers } = this.props;
      const { account_id } = locationUpdated;
      fetchInstallers(account_id);
    }

    if (persistLocation) {
      const { submitLocation } = this.props;
      submitLocation(locationUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        location: locationUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  switchTab = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const {
      /*  submitLocation, */
      brands,
      accounts,
      states,
      cities,
      installers,
      waterFilterConfigurationsForFilter,
      reverseOsmosisConfigurationsForFilter,
      softenerConfigurationsForFilter,
      fetchCities,
      fetchAccounts,
      loading,
    } = this.props;
    const { activeTab, location } = this.state;

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Configuration',
      },
      {
        title: 'Installer',
      },
    ];

    return (
      <Container>
        <Icon onClick={() => push('/locations')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Add new location
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={location}
            brands={brands}
            accounts={accounts}
            cities={cities}
            states={states}
            fetchCities={fetchCities}
            fetchAccounts={fetchAccounts}
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Configuration' && (
          <ConfigurationForm
            initialValues={location}
            waterFilterConfigurationsForFilter={waterFilterConfigurationsForFilter}
            reverseOsmosisConfigurationsForFilter={reverseOsmosisConfigurationsForFilter}
            softenerConfigurationsForFilter={softenerConfigurationsForFilter}
            back={this.back}
            onSubmit={this.saveData}
            waterFilterDisabled={false}
            reverseOsmosisDisabled={false}
            softenerDisabled={false}
          />
        )}
        {activeTab === 'Installer' && (
          <InstallerForm
            initialValues={location}
            installers={installers}
            back={this.back}
            onSubmit={this.saveData}
            loading={loading}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.locations.loading,
    brands: state.brands.listForFilter,
    accounts: state.accounts.listForFilter,
    states: state.utils.states,
    cities: state.utils.cities,
    installers: state.users.installers,
    waterFilterConfigurationsForFilter: state.libraries.waterFilterConfigurationsForFilter,
    reverseOsmosisConfigurationsForFilter: state.libraries.reverseOsmosisConfigurationsForFilter,
    softenerConfigurationsForFilter: state.libraries.softenerConfigurationsForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitLocation: alertType => dispatch(createLocation(alertType)),
    fetchStates: () => dispatch(getStates()),
    fetchCities: stateId => dispatch(getCitiesByState(stateId)),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchInstallers: accountId => dispatch(getInstallersByAccount(accountId)),
    fetchWaterFilterConfigurations: type => dispatch(getWaterFilterConfigurationsForFilter(type)),
    fetchReverseOsmosisConfigurations: type =>
      dispatch(getReverseOsmosisConfigurationsForFilter(type)),
    fetchSoftenerConfigurations: type => dispatch(getSoftenerConfigurationsForFilter(type)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);
// add the proptypes and sort them alphabetically

CreateLocation.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  cities: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchCities: PropTypes.func,
  fetchInstallers: PropTypes.func,
  fetchReverseOsmosisConfigurations: PropTypes.func,
  fetchSoftenerConfigurations: PropTypes.func,
  fetchStates: PropTypes.func,
  fetchWaterFilterConfigurations: PropTypes.func,
  installers: PropTypes.array,
  loading: PropTypes.bool,
  reverseOsmosisConfigurationsForFilter: PropTypes.array,
  softenerConfigurationsForFilter: PropTypes.array,
  states: PropTypes.array,
  submitLocation: PropTypes.func,
  waterFilterConfigurationsForFilter: PropTypes.array,
};
