import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getOtas(api, action) {
  const response = yield call(api.getOtas, action.payload);
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_OTAS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_OTAS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getOta(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getOta, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_OTA_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_OTA_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getOtaVersionsWfAndSoftener(api, action) {
  const response = yield call(api.getOtaVersions, { ...action.payload, device_type: 'filter' });
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getOtaVersionsRo(api, action) {
  const response = yield call(api.getOtaVersions, {
    ...action.payload,
    device_type: 'reverse-osmosis',
  });
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_OTA_VERSIONS_RO_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_OTA_VERSIONS_RO_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createOtaVersion(api, action) {
  const response = yield call(api.createOtaVersion, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Version created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_OTA_VERSION_SUCCESS,
      payload: data,
    });
    replace('/ota/manage-versions');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put({
        type: ActionTypes.CREATE_OTA_VERSION_FAILURE,
      });
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_USER_RESOLVED,
  });
}

export function* deleteOtaVersion(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteOtaVersion, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Version deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_OTA_VERSION_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;

    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.DELETE_OTA_VERSION_FAILURE,
      payload: { id },
    });
  }
}

export function* getUpgradeStatus(api, action) {
  const response = yield call(api.getUpgradeStatus, action.payload);
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_UPGRADE_STATUS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_UPGRADE_STATUS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* deleteUpgradeStatus(api, action) {
  const response = yield call(api.deleteUpgradeStatus, action.payload);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.DELETE_UPGRADE_STATUS_SUCCESS,
      id: action.payload,
    });
    yield put(showAlert('Job deleted', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.DELETE_UPGRADE_STATUS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getUpdateActiveDevices(api, action) {
  const response = yield call(api.getUpdateActiveDevices, action.payload);
  const { status, data } = response;

  if (status === 200) {
    const { rows } = data;

    yield put({
      type: ActionTypes.GET_UPDATE_ACTIVE_DEVICES_SUCCESS,
      payload: { rows },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_UPDATE_ACTIVE_DEVICES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getUpgradeStatusDevices(api, action) {
  const response = yield call(api.getUpgradeStatusDevices, action.payload);
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_UPGRADE_STATUS_DEVICES_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_UPGRADE_STATUS_DEVICES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createUpgradeStatus(api, action) {
  const response = yield call(api.createUpgradeStatus, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Upgrade status created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_UPGRADE_STATUS_SUCCESS,
      payload: data,
    });
    replace('/ota');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put({
        type: ActionTypes.CREATE_UPGRADE_STATUS_FAILURE,
      });
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_USER_RESOLVED,
  });
}

export function* getPreviousInstallationInfo(api, action) {
  const response = yield call(api.getPreviousInstallationInfo, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_PREVIOUS_INSTALLATION_INFO_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put({
        type: ActionTypes.GET_PREVIOUS_INSTALLATION_INFO_FAILURE,
      });
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_USER_RESOLVED,
  });
}
