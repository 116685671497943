import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Table = styled.div`
  overflow-x: auto;
  display: initial;
  max-width: 100%;
  margin: 0px 10px;
`;

export const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: ${props => (props.minWidth ? props.minWidth : '1000')}px;
  justify-content: center;
  align-items: center;
  border-top: 0.5px solid ${Colors.gray2};
  border-right: 0.5px solid ${Colors.gray2};
  border-left: 0.5px solid ${Colors.gray2};
  border-bottom: 0.5px solid ${Colors.gray2};
`;

export const TableTitle = styled.div`
  width: ${props => props.width};
  color: ${Colors.gray6};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  align-self: center;
  justify-content: center;
  padding: 8px 12px;
  line-height: 40px;
  border-right: 0.5px solid ${Colors.gray2};
  border-left: 0.5px solid ${Colors.gray2};
  white-space: nowrap;
`;

export const TBody = styled.div``;

export const TRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: ${props => (props.minWidth ? props.minWidth : '1000')}px;
  border-right: 0.5px solid ${Colors.gray2};
  border-left: 0.5px solid ${Colors.gray2};
  border-bottom: 0.5px solid ${Colors.gray2};
`;

export const TableItem = styled.div`
  width: ${props => props.width};
  color: ${props => props.color || Colors.black};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  justify-content: center;
  align-self: center;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 40px;
  border-right: 0.5px solid ${Colors.gray2};
  border-left: 0.5px solid ${Colors.gray2};
  white-space: nowrap;
  ${props =>
    !props.isComponent &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `};
`;

export const TableSubItem = styled.div`
  width: ${props => props.width};
  color: ${props => props.color || Colors.black};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  justify-content: center;
  align-self: center;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 40px;
  ${props =>
    !props.isComponent &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `};
`;

export default Table;
