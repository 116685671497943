import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAlerts,
  getAlertsTypes,
  getBrandsForFilter,
  getAccountsForFilter,
  getStates,
  getCitiesByState,
  deleteAlertType,
  markAlertAsViewed,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import ButtonText from 'components/ButtonText';
import AlertListView from './AlertListView';
import AlertTypeListView from './AlertTypeListView';

const Container = styled.div`
  padding-top: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: ${props => props.float || 'right'};
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: ${props => props.float || 'right'};
`;

class AlertListContainer extends Component {
  state = {
    tabSelected: 'alerts',
  };

  componentDidMount() {
    const { fetchAlerts, fetchBrands, fetchStates } = this.props;

    fetchAlerts();
    fetchBrands();
    fetchStates();
  }

  switchView = tabSelected => {
    this.setState(
      {
        tabSelected,
      },
      () => {
        const { fetchAlerts, fetchAlertsTypes } = this.props;
        if (tabSelected === 'alerts') {
          fetchAlerts();
        } else {
          fetchAlertsTypes();
        }
      },
    );
  };

  render() {
    const {
      alerts,
      alertsTypes,
      alertsPages,
      alertsCurrentPage,
      alertsTypesPages,
      alertsTypesCurrentPage,
      loadingAlert,
      loadingAlertType,
      fetchAlerts,
      fetchAlertsTypes,
      fetchCities,
      fetchAccounts,
      brands,
      accounts,
      states,
      cities,
      removeAlertType,
      role_code,
      markAlertAsViewed,
    } = this.props;

    const { tabSelected } = this.state;

    return (
      <Container>
        <Header>
          <ButtonContainer float="left">
            <ButtonGroup float="left">
              <ButtonText
                theme={tabSelected === 'alerts' ? 'round-alternative' : 'text'}
                color={tabSelected !== 'alerts' ? Colors.black : undefined}
                customStyles={tabSelected !== 'alerts' ? { padding: '7px 60px' } : {}}
                text="Alerts"
                onClick={() => this.switchView('alerts')}
              />
            </ButtonGroup>
            {['admin', 'general'].includes(role_code) && (
              <ButtonGroup float="left">
                <ButtonText
                  theme={tabSelected === 'alerts-types' ? 'round-alternative' : 'text'}
                  color={tabSelected !== 'alerts-types' ? Colors.black : undefined}
                  customStyles={tabSelected !== 'alerts-types' ? { padding: '7px 60px' } : {}}
                  text="Settings"
                  onClick={() => this.switchView('alerts-types')}
                />
              </ButtonGroup>
            )}
          </ButtonContainer>
          {tabSelected === 'alerts-types' && (
            <ButtonContainer float="right">
              <ButtonGroup>
                <ButtonText
                  theme="primary"
                  text="New"
                  icon="plus"
                  onClick={() => push('/alert-types/create')}
                />
              </ButtonGroup>
            </ButtonContainer>
          )}
        </Header>
        {tabSelected === 'alerts' ? (
          <AlertListView
            list={alerts}
            pages={alertsPages}
            currentPage={alertsCurrentPage}
            loading={loadingAlert}
            fetchAlerts={fetchAlerts}
            fetchAccounts={fetchAccounts}
            brands={brands}
            accounts={accounts}
            states={states}
            cities={cities}
            fetchCities={fetchCities}
            markAlertAsViewed={markAlertAsViewed}
          />
        ) : (
          <AlertTypeListView
            list={alertsTypes}
            pages={alertsTypesPages}
            currentPage={alertsTypesCurrentPage}
            loading={loadingAlertType}
            fetchAlertsTypes={fetchAlertsTypes}
            removeAlertType={removeAlertType}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    role_code: state.auth.profile.role_code,
    alerts: state.alerts.list,
    alertsPages: state.alerts.pages,
    alertsCurrentPage: state.alerts.currentPage,
    loadingAlert: state.alerts.loading,
    alertsTypes: state.alertsTypes.list,
    alertsTypesPages: state.alertsTypes.pages,
    alertsTypesCurrentPage: state.alertsTypes.currentPage,
    loadingAlertType: state.alertsTypes.loading,

    brands: [{ label: 'All', value: '' }, ...state.brands.listForFilter],
    accounts: [{ label: 'All', value: '' }, ...state.accounts.listForFilter],
    states: [{ label: 'All', value: '' }, ...state.utils.states],
    cities: [{ label: 'All', value: '' }, ...state.utils.cities],
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAlerts: params => dispatch(getAlerts(params)),
    fetchAlertsTypes: params => dispatch(getAlertsTypes(params)),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchStates: () => dispatch(getStates()),
    fetchCities: stateId => dispatch(getCitiesByState(stateId)),
    removeAlertType: alertTypeId => dispatch(deleteAlertType(alertTypeId)),
    markAlertAsViewed: params => dispatch(markAlertAsViewed(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AlertListContainer);
