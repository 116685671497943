import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioContainer = styled.span`
  display: grid;
  margin-top: 15px;
`;
const RadioButtonsBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.verticalAlign ? 'column' : 'row')};
  margin: ${props => (props.verticalAlign ? '5px 20%' : 'initial')};
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 5px 20%;
  }
`;
const Option = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13px;
  cursor: pointer;
`;
const RadioButton = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  color: white;
  border: 1px solid;
  border-color: ${props => (props.checked ? '#38b6ff' : '#999999')};
  padding-top: 7px;
  padding-bottom: 7px;
  color: ${props => (props.checked ? '#38b6ff' : '#999999')};
  margin-top: 10px;
  margin-bottom: 10px;
  width: ${props => (props.verticalAlign ? '100%' : '170px')};
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const OptionLabel = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
  color: #9e9e9e;
`;

export default props =>
  ((
    <RadioContainer>
      <OptionLabel>{props.label}</OptionLabel>
      <RadioButtonsBox verticalAlign={props.verticalAlign}>
        {props.options.map((option, index) => {
          let numericValue;
          if (props.reverseScored) {
            numericValue = props.options.length - index;
          } else {
            numericValue = index + 1;
          }
          return (
            <RadioButton
              key={index}
              checked={props.currentValue === numericValue}
              onClick={() => props.handleChange(props.name, numericValue)}
              verticalAlign={props.verticalAlign}
            >
              {props.currentValue === numericValue ? (
                <i
                  className="fas fa-check-circle"
                  style={{
                    color: '#38b6ff',
                    marginLeft: '8px',
                    marginRight: '12px',
                  }}
                />
              ) : (
                <i
                  className="fas fa-circle"
                  style={{
                    color: '#999999',
                    marginLeft: '8px',
                    marginRight: '12px',
                  }}
                />
              )}

              <Option>{option}</Option>
            </RadioButton>
          );
        })}
      </RadioButtonsBox>
    </RadioContainer>
  ).propTypes = {
    currentValue: PropTypes.number,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    reverseScored: PropTypes.bool,
    verticalAlign: PropTypes.bool,
  });
