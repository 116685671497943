import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from './Icon';

const Container = styled.button`
  position: relative;
  font-style: normal;
  line-height: 22px;
  outline: none;
  cursor: pointer;
  ${props => props.full && 'width: 100%'};
  padding: ${props => (props.theme !== 'text' ? '7px 60px' : '0px')};
  font-size: ${props => (props.small ? '12px' : '18px')};

  &: hover {
    opacity: 0.8;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;   
  `}
`;

export default class ButtonText extends Component {
  getBorderColor = () => {
    const { theme } = this.props;

    switch (theme) {
      case 'primary-blue':
        return `solid ${Colors.blue} 1px`;
      case 'primary':
        return `solid ${Colors.lightBlue} 1px`;
      case 'basic':
        return `solid ${Colors.gray6} 1px`;
      case 'text':
        return Colors.transparent;
      case 'warning':
        return `solid ${Colors.orange} 1px`;
      case 'success':
        return `solid ${Colors.green} 1px`;
      case 'round':
        return `solid ${Colors.lightBlue} 2px`;
      case 'round-alternative':
        return `solid ${Colors.blue} 1px`;
      case 'solid':
        return `solid ${Colors.lightBlue} 1px`;
      case 'default':
        return `solid ${Colors.gray2} 1px`;
      case 'secondary':
        return `solid ${Colors.gray3} 1px`;
      case 'danger':
        return Colors.transparent;
      case 'dashed':
        return `dashed ${Colors.gray4}`;
      default:
        return Colors.transparent;
    }
  };

  getBackground = () => {
    const { theme } = this.props;

    switch (theme) {
      case 'primary-blue':
        return Colors.blue;
      case 'primary':
        return Colors.lightBlue;
      case 'basic':
        return Colors.transparent;
      case 'text':
        return Colors.transparent;
      case 'warning':
        return Colors.transparent;
      case 'success':
        return Colors.transparent;
      case 'round':
        return Colors.transparent;
      case 'round-alternative':
        return Colors.transparent;
      case 'solid':
        return Colors.lightBlue;
      case 'default':
        return Colors.gray2;
      case 'danger':
        return Colors.red;
      case 'secondary':
        return Colors.gray3;
      case 'dashed':
        return Colors.transparent;
      default:
        return Colors.transparent;
    }
  };

  getColor = () => {
    const { theme, color } = this.props;

    if (color) {
      return color;
    }

    switch (theme) {
      case 'primary':
        return Colors.white;
      case 'basic':
        return Colors.gray6;
      case 'text':
        return Colors.lightBlue;
      case 'warning':
        return Colors.orange;
      case 'round':
        return Colors.lightBlue;
      case 'success':
        return Colors.green;
      case 'solid':
        return Colors.white;
      case 'default':
        return Colors.black;
      case 'danger':
        return Colors.white;
      case 'round-alternative':
        return Colors.blue;
      case 'round-alternative':
        return Colors.white;
      case 'dashed':
        return Colors.lightBlue;
      default:
        return Colors.white;
    }
  };

  getBorderRadius = () => {
    const { theme } = this.props;

    switch (theme) {
      case 'primary-blue':
        return '5px';
      case 'primary':
        return '5px';
      case 'basic':
        return '5px';
      case 'text':
        return '0px';
      case 'warning':
        return '30px';
      case 'success':
        return '30px';
      case 'round':
        return '30px';
      case 'round-alternative':
        return '30px';
      case 'solid':
        return '30px';
      case 'default':
        return '5px';
      case 'secondary':
        return '5px';
      case 'danger':
        return '5px';
      case 'dashed':
        return '0px';
      default:
        return '0px';
    }
  };

  render() {
    const { icon, text, customStyles } = this.props;

    return (
      <Container
        {...this.props}
        style={{
          backgroundColor: this.getBackground(),
          color: this.getColor(),
          borderRadius: this.getBorderRadius(),
          border: this.getBorderColor(),
          ...(customStyles || {}),
        }}
      >
        {icon && (
          <Icon name={icon} color={this.getColor()} customStyle={{ paddingRight: '10px' }} />
        )}
        {text}
      </Container>
    );
  }
}

ButtonText.propTypes = {
  color: PropTypes.string,
  customStyles: PropTypes.object,
  icon: PropTypes.string,
  small: PropTypes.bool,
  text: PropTypes.string,
  theme: PropTypes.string,
};
