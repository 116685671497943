import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default class DetailsForm extends Component {
  componentDidMount() {
    const { getCities, initialValues } = this.props;
    if (initialValues.state_id) {
      getCities(initialValues.state_id);
    }
  }

  submit = values => {
    const { onSubmit } = this.props;
    const activeTab = 'Locations';

    onSubmit(values, activeTab);
  };

  render() {
    const { initialValues, isInitialValid, states, cities, getCities, brands } = this.props;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          address_line: Yup.string().required('Required'),
          brand_id: Yup.string().required('Required'),
          city_id: Yup.string().required('Required'),
          contact_email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          contact_first_name: Yup.string().required('Required'),
          contact_last_name: Yup.string().required('Required'),
          contact_phone_number: Yup.string().required('Required'),
          name: Yup.string().required('Required'),
          phone_number: Yup.string().required('Required'),
          state_id: Yup.string().required('Required'),
          zip_code: Yup.string()
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
              message: 'Invalid zipcode',
            })
            .required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Row>
              <Column style={{ marginRight: '15px' }} />
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.lightBlue}>
                  Primary contact
                </H4>
              </Column>
            </Row>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Name
                    </H4>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Phone number
                    </H4>
                    <Input
                      placeholder="Phone number"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="phone"
                    />
                    <FormError
                      text={errors.phone_number && touched.phone_number && errors.phone_number}
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Brand
                    </H4>
                    <Select
                      placeholder="Brand"
                      name="brand_id"
                      options={brands}
                      value={values.brand_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.brand_id && touched.brand_id && errors.brand_id} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line
                    </H4>
                    <Input
                      placeholder="Address line"
                      name="address_line"
                      value={values.address_line}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError
                      text={errors.address_line && touched.address_line && errors.address_line}
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line 2
                    </H4>
                    <Input
                      placeholder="Address line 2"
                      name="address_line_1"
                      value={values.address_line_1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError
                      text={
                        errors.address_line_1 && touched.address_line_1 && errors.address_line_1
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <Column style={{ marginRight: '10px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      State
                    </H4>
                    <Select
                      placeholder="State"
                      name="state_id"
                      options={states}
                      value={values.state_id}
                      onChange={evt => {
                        getCities(evt.target.value);
                        handleChange(evt);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.state_id && touched.state_id && errors.state_id} />
                  </Column>
                  <Column style={{ marginLeft: '10px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      City
                    </H4>
                    <Select
                      placeholder="City"
                      name="city_id"
                      options={cities}
                      value={values.city_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.city_id && touched.city_id && errors.city_id} />
                  </Column>
                </Row>
                <Row>
                  <Column style={{ paddingRight: '10px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      ZIP
                    </H4>
                    <Input
                      placeholder="ZIP"
                      name="zip_code"
                      type="number"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError text={errors.zip_code && touched.zip_code && errors.zip_code} />
                  </Column>
                </Row>
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  First name
                </H4>
                <Input
                  placeholder="First name"
                  name="contact_first_name"
                  value={values.contact_first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="user"
                />
                <FormError
                  text={
                    errors.contact_first_name &&
                    touched.contact_first_name &&
                    errors.contact_first_name
                  }
                />

                <H4 inputLabel color={Colors.gray8}>
                  Last name
                </H4>
                <Input
                  placeholder="Last name"
                  name="contact_last_name"
                  value={values.contact_last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="user"
                />
                <FormError
                  text={
                    errors.contact_last_name &&
                    touched.contact_last_name &&
                    errors.contact_last_name
                  }
                />

                <H4 inputLabel color={Colors.gray8}>
                  Email
                </H4>
                <Input
                  placeholder="Email"
                  name="contact_email"
                  value={values.contact_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="envelope"
                />
                <FormError
                  text={errors.contact_email && touched.contact_email && errors.contact_email}
                />

                <H4 inputLabel color={Colors.gray8}>
                  Phone number
                </H4>
                <Input
                  placeholder="Phone number"
                  name="contact_phone_number"
                  value={values.contact_phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="phone"
                />
                <FormError
                  text={
                    errors.contact_phone_number &&
                    touched.contact_phone_number &&
                    errors.contact_phone_number
                  }
                />
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText text="Next" theme="primary" type="submit" />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailsForm.propTypes = {
  brands: PropTypes.array,
  cities: PropTypes.array,
  getCities: PropTypes.func,
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  onSubmit: PropTypes.func,
  states: PropTypes.array,
};
