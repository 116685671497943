import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getAccount,
  editAccount,
  getStates,
  getCitiesByState,
  getBrandsForFilter,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import LocationsForm from './LocationsForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class EditAccount extends Component {
  state = {
    activeTab: 'Details',
    account: {},
  };

  componentDidMount() {
    const {
      match: { params },
      fetchAccount,
      fetchStates,
      fetchBrandsForFilter,
    } = this.props;
    const { id } = params;

    fetchAccount(id);
    fetchStates();
    fetchBrandsForFilter();
  }

  getCities = stateId => {
    const { fetchCitiesByState } = this.props;

    fetchCitiesByState(stateId);
  };

  saveData = (data, nextTab) => {
    const { account, activeTab } = this.state;
    const accountUpdated = {
      ...account,
      ...data,
    };

    if (activeTab === 'Locations') {
      const {
        submitAccount,
        match: { params },
      } = this.props;
      const { id } = params;

      submitAccount(id, accountUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        account: accountUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  render() {
    const { loading, account, states, cities, token, brands } = this.props;
    const {
      activeTab,
      account: { locations },
    } = this.state;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!account) {
      return null;
    }

    const detailsInitialValues = {
      name: account.name,
      address_line: account.address_line,
      address_line_1: account.address_line_1,
      city_id: account.city_id,
      state_id: account.state_id,
      zip_code: account.zip_code,
      phone_number: account.phone_number,
      contact_first_name: account.contact_first_name,
      contact_last_name: account.contact_last_name,
      contact_phone_number: account.contact_phone_number,
      contact_email: account.contact_email,
      brand_id: account.brand_id,
    };

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Locations',
      },
    ];

    return (
      <Container>
        <Icon onClick={() => push('/accounts')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Edit account
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            isInitialValid
            cities={cities}
            states={states}
            brands={brands}
            getCities={this.getCities}
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Locations' && (
          <LocationsForm
            token={token}
            locations={locations}
            back={() => this.back('Details')}
            onSubmit={this.saveData}
            dropzoneTitle="Choose CSV File to add new locations"
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.accounts.item,
    loading: state.accounts.loading,
    states: state.utils.states,
    cities: state.utils.cities,
    token: state.auth.token,
    brands: state.brands.listForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitAccount: (id, data) => dispatch(editAccount(id, data)),
    fetchAccount: id => dispatch(getAccount(id)),
    fetchStates: () => dispatch(getStates()),
    fetchCitiesByState: stateId => dispatch(getCitiesByState(stateId)),
    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);

// add proptypes and sort alpahebtically

EditAccount.propTypes = {
  account: PropTypes.object,
  brands: PropTypes.array,
  cities: PropTypes.array,
  fetchAccount: PropTypes.func,
  fetchBrandsForFilter: PropTypes.func,
  fetchCitiesByState: PropTypes.func,
  fetchStates: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.object,
  states: PropTypes.array,
  submitAccount: PropTypes.func,
  token: PropTypes.string,
};
