import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';

export function* getSupports(api, action) {
  const response = yield call(api.getSupports, action.payload);
  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_SUPPORTS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SUPPORTS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* checkAsRead(api, action) {
  const { id } = action.payload;
  const response = yield call(api.checkAsRead, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.CHECK_AS_READ_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.CHECK_AS_READ_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* deleteSupport(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteSupport, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Support deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_SUPPORT_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.DELETE_SUPPORT_FAILURE,
      payload: { id },
    });
  }
}
