import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getLibraries(api, action) {
  const response = yield call(api.getLibraries, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_LIBRARIES_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_LIBRARIES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getCatridge(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getCatridge, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CATRIDGE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CATRIDGE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getCatridgesForFilter(api) {
  const response = yield call(api.getCatridgesForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CATRIDGES_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CATRIDGES_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisMembraneForFilter(api) {
  const response = yield call(api.getReverseOsmosisMembraneForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisForFilter(api) {
  const response = yield call(api.getReverseOsmosisForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerForFilter(api) {
  const response = yield call(api.getSoftenerForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getBrineDrumForFilter(api) {
  const response = yield call(api.getBrineDrumForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_BRINE_DRUM_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_BRINE_DRUM_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisMembrane(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getReverseOsmosisMembrane, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosis(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getReverseOsmosis, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftener(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getSoftener, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getBrineDrum(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getBrineDrum, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_BRINE_DRUM_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_BRINE_DRUM_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getTanks(api) {
  const response = yield call(api.getTanks);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TANKS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_TANKS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterConfigurationsForFilter(api) {
  const type = 'water-filter-configuration';
  const response = yield call(api.getConfigurationsForFilter, type);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisConfigurationsForFilter(api) {
  const type = 'reverse-osmosis-configuration';
  const response = yield call(api.getConfigurationsForFilter, type);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerConfigurationsForFilter(api) {
  const type = 'water-softener-configuration';
  const response = yield call(api.getConfigurationsForFilter, type);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFiltersForFilter(api) {
  const response = yield call(api.getWaterFiltersForFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTERS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTERS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilter(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getWaterFilter, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getConfiguration(api, action) {
  const { id, type } = action.payload;
  const response = yield call(api.getConfiguration, id, type);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CONFIGURATION_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CONFIGURATION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSensor(api, action) {
  const { id, type } = action.payload;
  const response = yield call(api.getSensor, id, type);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SENSOR_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SENSOR_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createWaterFilter(api, action) {
  const response = yield call(api.createWaterFilter, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Water filter created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_WATER_FILTER_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_WATER_FILTER_FAILURE,
    });
  }
}

export function* createWaterCatridge(api, action) {
  const response = yield call(api.createWaterCatridge, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Water catridge created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_WATER_CATRIDGE_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_WATER_CATRIDGE_FAILURE,
    });
  }
}

export function* createDeviceConfiguration(api, action) {
  const { device_type } = action.payload;
  const response = yield call(api.createDeviceConfiguration, action.payload, device_type);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Device configuration created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_CONFIGURATION_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_CONFIGURATION_FAILURE,
    });
  }
}

export function* createSoftener(api, action) {
  const response = yield call(api.createSoftener, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Softener created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_SOFTENER_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_SOFTENER_FAILURE,
    });
  }
}

export function* createReverseOsmosis(api, action) {
  const response = yield call(api.createReverseOsmosis, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Reverse osmosis created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_REVERSE_OSMOSIS_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_REVERSE_OSMOSIS_FAILURE,
    });
  }
}

export function* createBrineDrum(api, action) {
  const response = yield call(api.createBrineDrum, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Brine drum created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_BRINE_DRUM_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_BRINE_DRUM_FAILURE,
    });
  }
}

export function* createReverseOsmosisMembrane(api, action) {
  const response = yield call(api.createReverseOsmosisMembrane, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Reverse osmosis membrane created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE_FAILURE,
    });
  }
}

export function* createSensor(api, action) {
  const { sensor_type } = action.payload;
  const response = yield call(api.createSensor, action.payload, sensor_type);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Sensor created', { variant: 'success' }));
    yield put({
      type: ActionTypes.CREATE_SENSOR_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.CREATE_SENSOR_FAILURE,
    });
  }
}

export function* editWaterFilter(api, action) {
  const { id, waterFilter } = action.payload;
  const response = yield call(api.editWaterFilter, id, waterFilter);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Water filter edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_WATER_FILTER_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_WATER_FILTER_FAILURE,
    });
  }
}

export function* editWaterCatridge(api, action) {
  const { id, waterCatridge } = action.payload;
  const response = yield call(api.editWaterCatridge, id, waterCatridge);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Water catridge edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_WATER_CATRIDGE_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_WATER_CATRIDGE_FAILURE,
    });
  }
}

export function* editDeviceConfiguration(api, action) {
  const { id, type, deviceConfiguration } = action.payload;
  const response = yield call(api.editDeviceConfiguration, deviceConfiguration, id, type);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Device configuration edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_CONFIGURATION_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_CONFIGURATION_FAILURE,
    });
  }
}

export function* editSensor(api, action) {
  const { id, type, sensor } = action.payload;
  const response = yield call(api.editSensor, sensor, id, type);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Sensor edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_SENSOR_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_SENSOR_FAILURE,
    });
  }
}

export function* editSoftener(api, action) {
  const { id, softener } = action.payload;

  const response = yield call(api.editSoftener, id, softener);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Softener edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_SOFTENER_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_SOFTENER_FAILURE,
    });
  }
}

export function* editReverseOsmosis(api, action) {
  const { id, reverseOsmosis } = action.payload;

  const response = yield call(api.editReverseOsmosis, id, reverseOsmosis);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Reverse osmosis edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_REVERSE_OSMOSIS_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_REVERSE_OSMOSIS_FAILURE,
    });
  }
}

export function* editBrineDrum(api, action) {
  const { id, brineDrum } = action.payload;

  const response = yield call(api.editBrineDrum, id, brineDrum);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Brine drum edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_BRINE_DRUM_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_BRINE_DRUM_FAILURE,
    });
  }
}

export function* editReverseOsmosisMembrane(api, action) {
  const { id, reverseOsmosisMembrane } = action.payload;

  const response = yield call(api.editReverseOsmosisMembrane, id, reverseOsmosisMembrane);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Reverse osmosis membrane edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE_SUCCESS,
    });
    replace('/libraries');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE_FAILURE,
    });
  }
}

export function* deleteLibrary(api, action) {
  const { id, reference, type } = action.payload;
  const response = yield call(api.deleteLibrary, reference, type);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Library deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_LIBRARY_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.DELETE_LIBRARY_FAILURE,
    });
  }
}

export function* getFlowSensorsForSelect(api) {
  const response = yield call(api.getFlowSensorsForSelect);

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_FLOW_SENSORS_FOR_SELECT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_FLOW_SENSORS_FOR_SELECT_FAILURE,
    });
  }
}

export function* getPressureSensorsForSelect(api) {
  const response = yield call(api.getPressureSensorsForSelect);

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT_FAILURE,
    });
  }
}

export function* getDistanceSensorsForSelect(api) {
  const response = yield call(api.getDistanceSensorsForSelect);

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT_FAILURE,
    });
  }
}

export function* getTdsProbeSensorsForSelect(api) {
  const response = yield call(api.getTdsProbeSensorsForSelect);

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT_FAILURE,
    });
  }
}

export function* getTemperatureSensorsForSelect(api) {
  const response = yield call(api.getTemperatureSensorsForSelect);

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT_FAILURE,
    });
  }
}
