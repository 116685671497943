import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled, { css, ThemeProvider } from 'styled-components';

import history from 'modules/history';
import theme from 'modules/theme';

import config from 'config';
import { startup } from 'actions';

import UIComponents from 'routes/UIComponents';
import NotFound from 'routes/NotFound';
import NotFoundServer from 'routes/NotFoundServer';
import Unauthorized from 'routes/Unauthorized';
import ExpiredToken from 'routes/ExpiredToken';
import ComingSoon from 'routes/ComingSoon';
import Login from 'routes/Login';
import RecoverPassword from 'routes/RecoverPassword';
import ResetPassword from 'routes/ResetPassword';
import NewPassword from 'routes/NewPassword';
import CreateLocation from 'routes/Location/CreateLocation';
import EditLocation from 'routes/Location/EditLocation';
import LocationList from 'routes/LocationList';
import LocationDetail from 'routes/LocationDetail/LocationDetailContainer';

import LibraryList from 'routes/LibraryList';
// Water Filter
import CreateWaterFilter from 'routes/WaterFilter/CreateWaterFilter';
import EditWaterFilter from 'routes/WaterFilter/EditWaterFilter';
// Cartridge
import CreateCatridge from 'routes/Catridge/CreateCatridge';
import EditCatridge from 'routes/Catridge/EditCatridge';
// RO Membrane
import CreateROMembrane from 'routes/ROMembrane/CreateROMembrane';
import EditROMembrane from 'routes/ROMembrane/EditROMembrane';
// Reverse Osmosis Filter
import CreateReverseOsmosisFilter from 'routes/ReverseOsmosisFilter/CreateReverseOsmosisFilter';
import EditReverseOsmosisFilter from 'routes/ReverseOsmosisFilter/EditReverseOsmosisFilter';
// Brine Drum
import CreateBrineDrum from 'routes/BrineDrum/CreateBrineDrum';
import EditBrineDrum from 'routes/BrineDrum/EditBrineDrum';
// Softener
import CreateSoftener from 'routes/Softener/CreateSoftener';
import EditSoftener from 'routes/Softener/EditSoftener';
// Configuration
import CreateDeviceConfiguration from 'routes/DeviceConfiguration/CreateDeviceConfiguration';
import EditDeviceConfiguration from 'routes/DeviceConfiguration/EditDeviceConfiguration';
// Sensors
import CreateSensor from 'routes/Sensor/CreateSensor';
import EditSensor from 'routes/Sensor/EditSensor';

import AlertList from 'routes/AlertList';
import CreateAlertType from 'routes/AlertType/CreateAlertType';
import EditAlertType from 'routes/AlertType/EditAlertType';
import UserList from 'routes/UserList';
import CreateUser from 'routes/User/CreateUser';
import EditUser from 'routes/User/EditUser';
import AccountList from 'routes/AccountList';
import CreateAccount from 'routes/Account/CreateAccount';
import EditAccount from 'routes/Account/EditAccount';
import BrandList from 'routes/BrandList';
import CreateBrand from 'routes/Brand/CreateBrand';
import EditBrand from 'routes/Brand/EditBrand';
import OtaList from 'routes/Ota/OtaList';
import OtaUpgradeStatus from 'routes/Ota/OtaUpgradeStatus';
import OtaUpgradeStatusLocations from 'routes/Ota/OtaUpgradeStatusLocations';
import OtaManageVersions from 'routes/Ota/OtaManageVersions';
import OtaActiveDevices from 'routes/Ota/OtaActiveDevices';
import OtaNewVersion from 'routes/Ota/OtaNewVersion';
import Settings from 'routes/Settings';
import Supports from 'routes/SupportsList';
import SystemAlerts from 'components/SystemAlerts';

import GlobalStyles from 'components/GlobalStyles';
import RoutePublic from 'components/RoutePublic';
import RoutePrivate from 'components/RoutePrivate';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const MainPrivate = ({ isAuthenticated }) =>
  isAuthenticated &&
  css`
    padding: 0 0 0;
  `;

const Main = styled.main`
  min-height: 100vh;
  ${MainPrivate};
`;

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.startupApp();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    startupApp: PropTypes.func.isRequired,
  };

  render() {
    const {
      auth: { profile, isAuthenticated },
    } = this.props;
    const role_code = profile ? profile.role_code : 'guest';

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppWrapper logged={isAuthenticated}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: 'pt-br' }}
              encodeSpecialCharacters
              defaultTitle={config.name}
              titleTemplate={`%s | ${config.name}`}
              titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
            />
            <Main isAuthenticated={isAuthenticated}>
              <Switch>
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/login"
                  exact
                  component={Login}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/recover-password"
                  exact
                  component={RecoverPassword}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/reset-password"
                  component={ResetPassword}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/new-password"
                  component={NewPassword}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  path="/"
                  exact
                  component={LocationList}
                  role_code={role_code}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/water-filter"
                  component={CreateWaterFilter}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/water-filter"
                  component={EditWaterFilter}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/catridge"
                  component={CreateCatridge}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/catridge"
                  component={EditCatridge}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/ro-membrane"
                  component={CreateROMembrane}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/ro-membrane"
                  component={EditROMembrane}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/reverse-osmosis-filter"
                  component={CreateReverseOsmosisFilter}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/reverse-osmosis-filter"
                  component={EditReverseOsmosisFilter}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/brine-drum"
                  component={CreateBrineDrum}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/brine-drum"
                  component={EditBrineDrum}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/softener"
                  component={CreateSoftener}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/:id/softener"
                  component={EditSoftener}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/configuration/:id/:type"
                  component={EditDeviceConfiguration}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/device-configuration"
                  component={CreateDeviceConfiguration}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/create/sensor"
                  component={CreateSensor}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries/edit/sensor/:id/:type"
                  component={EditSensor}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/libraries"
                  component={LibraryList}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/locations/create"
                  component={CreateLocation}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/locations/:id/edit"
                  component={EditLocation}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  path="/locations/:id"
                  exact
                  component={LocationDetail}
                  role_code={role_code}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/locations"
                  component={LocationList}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/alerts"
                  component={AlertList}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/alert-types/edit/:id"
                  component={EditAlertType}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/alert-types/create"
                  component={CreateAlertType}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/users/:id/edit"
                  component={EditUser}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/users/create"
                  component={CreateUser}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/users"
                  component={UserList}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/accounts/:id/edit"
                  component={EditAccount}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  exact
                  path="/accounts/create"
                  component={CreateAccount}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/accounts"
                  component={AccountList}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/accounts/create"
                  component={ComingSoon}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  exact
                  path="/brands/create"
                  component={CreateBrand}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/brands/:id/edit"
                  component={EditBrand}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/brands"
                  exact
                  component={BrandList}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota/manage-versions"
                  component={OtaManageVersions}
                  exact
                  allow={['admin', 'general']}
                />

                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota/manage-versions/new/:device_type"
                  component={OtaNewVersion}
                  exact
                  allow={['admin', 'general']}
                />

                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota/active-devices/:update_id"
                  component={OtaActiveDevices}
                  exact
                  allow={['admin', 'general']}
                />

                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota/upgrade-status"
                  component={OtaUpgradeStatus}
                  exact
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota/upgrade-status/locations/:otaUpdateId"
                  component={OtaUpgradeStatusLocations}
                  exact
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/ota"
                  exact
                  component={OtaList}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/settings"
                  component={Settings}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/supports"
                  component={Supports}
                  allow={['admin', 'general']}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  role_code={role_code}
                  path="/components"
                  component={UIComponents}
                  allow={['admin', 'general', 'customer', 'external', 'installer', 'dealer']}
                />
                <RoutePublic path="/internal-server-error" component={NotFoundServer} />
                <RoutePublic path="/unauthorized-error" component={Unauthorized} />
                <RoutePublic path="/expired-token" component={ExpiredToken} />

                <Route component={NotFound} />
              </Switch>
            </Main>
            {/* <Footer /> */}
            <SystemAlerts />
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startupApp: () => dispatch(startup()),
  };
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
