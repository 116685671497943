import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';

export function* getRoles(api) {
  const { status, data } = yield call(api.getRoles);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ROLES_SUCCESS,
      payload: { roles: data },
    });
  } else {
    yield put({
      type: ActionTypes.GET_ROLES_FAILURE,
    });
    yield put(showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getStates(api) {
  const { status, data } = yield call(api.getStates);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_STATES_SUCCESS,
      payload: { states: data },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_STATES_FAILURE,
    });
  }
}

export function* getCitiesByState(api, action) {
  const {
    payload: { stateId },
  } = action;
  const { status, data } = yield call(api.getCitiesByState, stateId);

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CITIES_BY_STATE_SUCCESS,
      payload: { cities: data },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.GET_CITIES_BY_STATE_FAILURE,
    });
  }
}
