import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { NonInternalRoles, CustomerRoleId } from 'constants/index';
import MultiSelect from '@khanacademy/react-multi-select';
import { Colors } from 'modules/theme';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: ${props => props.width || 50}%;
  width @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export default class PermissionsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandsSelected: props.brandsSelected || [],
      accountsSelected: props.accountsSelected || [],
      locationsSelected: props.locationsSelected || [],
    };
  }

  componentDidMount() {
    const { fetchAccounts, fetchLocations, brandsSelected, accountsSelected } = this.props;

    fetchAccounts({ brands: brandsSelected });
    fetchLocations({ accounts: accountsSelected });
  }

  submit = values => {
    const { onSubmit } = this.props;
    const { brandsSelected, accountsSelected, locationsSelected } = this.state;
    const activeTab = 'Permissions';

    onSubmit(
      {
        ...values,
        brands: brandsSelected,
        accounts: accountsSelected,
        locations: locationsSelected,
      },
      activeTab,
    );
  };

  reset = () => {
    this.setState({
      brandsSelected: [],
      accountsSelected: [],
      locationsSelected: [],
    });
  };

  render() {
    const {
      initialValues,
      isInitialValid,
      roles,
      brands,
      accounts,
      locations,
      fetchAccounts,
      fetchLocations,
    } = this.props;
    const { brandsSelected, accountsSelected, locationsSelected } = this.state;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          role_id: Yup.number().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form>
            <Row>
              <Column style={{ paddingRight: '10px', width: `${100 / 3}%` }}>
                <H4 inputLabel color={Colors.gray8}>
                  Role
                </H4>
                <Select
                  placeholder="Role"
                  name="role_id"
                  options={roles}
                  value={values.role_id}
                  onChange={event => {
                    handleChange(event);
                    this.reset();
                  }}
                  onBlur={handleBlur}
                />
                <FormError text={errors.role_id && touched.role_id && errors.role_id} />
              </Column>
            </Row>
            {NonInternalRoles.includes(parseInt(values.role_id)) && (
              <Row>
                <Column style={{ marginRight: '10px', width: `${100 / 3}%` }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Brands
                  </H4>
                  {parseInt(values.role_id) === CustomerRoleId ? (
                    <Select
                      placeholder="Select some brands..."
                      options={brands}
                      value={brandsSelected.length ? brandsSelected[0] : ''}
                      onChange={event => {
                        const brandsSelected = [event.target.value];
                        fetchAccounts({ brands: brandsSelected });
                        this.setState({
                          brandsSelected,
                          accountsSelected: [],
                          locationsSelected: [],
                        });
                      }}
                      onBlur={handleBlur}
                    />
                  ) : (
                    <MultiSelect
                      options={brands}
                      selected={brandsSelected}
                      hasSelectAll={brands.length > 0}
                      overrideStrings={{
                        selectSomeItems: 'Select some brands...',
                      }}
                      onSelectedChanged={brandsSelected => {
                        fetchAccounts({ brands: brandsSelected });
                        this.setState({
                          brandsSelected,
                          accountsSelected: [],
                          locationsSelected: [],
                        });
                      }}
                    />
                  )}
                </Column>
                <Column style={{ marginLeft: '10px', marginRight: '10px', width: `${100 / 3}%` }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Accounts
                  </H4>
                  <MultiSelect
                    options={accounts}
                    selected={accountsSelected}
                    hasSelectAll={accounts.length > 0}
                    overrideStrings={{
                      selectSomeItems: 'Select some accounts...',
                    }}
                    onSelectedChanged={accountsSelected => {
                      fetchLocations({ accounts: accountsSelected });
                      this.setState({
                        accountsSelected,
                        locationsSelected: [],
                      });
                    }}
                  />
                </Column>
                <Column style={{ marginLeft: '10px', width: `${100 / 3}%` }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Locations
                  </H4>
                  <MultiSelect
                    options={locations}
                    selected={locationsSelected}
                    hasSelectAll={locations.length > 0}
                    overrideStrings={{
                      selectSomeItems: 'Select some locations...',
                    }}
                    onSelectedChanged={locationsSelected => this.setState({ locationsSelected })}
                  />
                </Column>
              </Row>
            )}
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText text="Next" theme="primary" type="submit" disabled={!isValid} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

PermissionsForm.propTypes = {
  accounts: PropTypes.array,
  accountsSelected: PropTypes.array,
  brands: PropTypes.array,
  brandsSelected: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchLocations: PropTypes.func,
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  locations: PropTypes.array,
  locationsSelected: PropTypes.array,
  onSubmit: PropTypes.func,
  roles: PropTypes.array,
};
