import { put, call, select, delay } from 'redux-saga/effects';
import { showAlert, getLocation as getLocationFunction } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';
import moment from 'moment';

export function* getLocations(api, action) {
  const response = yield call(api.getLocations, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_LOCATIONS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_LOCATIONS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getLocation(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getLocation, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_LOCATION_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_LOCATION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getLocationsForFilter(api, action) {
  const response = yield call(api.getLocationsForFilter, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_LOCATIONS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_LOCATIONS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* createLocation(api, action) {
  const response = yield call(api.createLocation, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Location created', { variant: 'success' }));
    replace('/locations');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_LOCATION_RESOLVED,
  });
}

export function* editLocation(api, action) {
  const { id, location } = action.payload;
  const response = yield call(api.editLocation, id, location);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Location edited', { variant: 'success' }));
    replace('/locations');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }

  yield put({
    type: ActionTypes.EDIT_LOCATION_RESOLVED,
  });
}

export function* deleteLocation(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteLocation, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Location deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_LOCATION_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    yield put(showAlert(errors.message, { variant: 'danger' }));
    yield put({
      type: ActionTypes.DELETE_LOCATION_FAILURE,
      payload: { id },
    });
  }
}

export function* changeMembranes(api, action) {
  const response = yield call(api.changeMembranes, action.payload);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Changed membrane', { variant: 'success' }));
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getCsvFiles(api, action) {
  const response = yield call(api.getCsvFiles, action.payload);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CSV_FILES_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put({
        type: ActionTypes.GET_CSV_FILES_FAILURE,
        // payload: { id },
      });

      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* setDeviceId(api, action) {
  const response = yield call(api.setDeviceId, action.payload);
  const { status, data } = response;
  if (status === 200) {
    const { exist } = data;
    const { location_id } = action.payload;
    yield put({
      type: ActionTypes.SET_DEVICE_ID_SUCCESS,
    });
    if (exist) {
      yield put(showAlert('Device ID updated ', { variant: 'success' }));
    } else {
      yield put(
        showAlert('Device ID updated, but it was not found in the database  ', {
          variant: 'warning',
          timeout: 9,
        }),
      );
    }
    yield put(getLocationFunction(location_id));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.SET_DEVICE_ID_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* updateInstallationStatus(api, action) {
  const { id, location } = action.payload;
  const response = yield call(api.updateInstallationStatus, id, location);
  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Location edited', { variant: 'success' }));
    yield put({
      type: ActionTypes.UPDATE_INSTALLATION_STATUS_SUCCESS,
    });
    yield put(getLocationFunction(id));
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
    yield put({
      type: ActionTypes.UPDATE_INSTALLATION_STATUS_FAILURE,
    });
  }
}

export function* getWaterFilterCurrentState(api, action) {
  const { device_id, from } = action.payload;

  const response = yield call(api.getWaterFilterCurrentState, device_id, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_STATE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_STATE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterCurrentPressure(api, action) {
  const { device_id, from } = action.payload;
  const response = yield call(api.getWaterFilterCurrentPressure, device_id, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterConsumption(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getWaterFilterConsumption, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CONSUMPTION_SUCCESS,
      payload: data,
    });
    yield delay(1500);

    if (action.payload.wf_for_pfd) {
      yield put({
        type: ActionTypes.WF_FOR_PDF,
        wf_for_pfd: action.payload.wf_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CONSUMPTION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterMaxFlowRate(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getWaterFilterMaxFlowRate, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE_SUCCESS,
      payload: data,
    });
    yield delay(1500);

    if (action.payload.wf_for_pfd) {
      yield put({
        type: ActionTypes.WF_FOR_PDF,
        wf_for_pfd: action.payload.wf_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterPressureForFlowRate(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getWaterFilterPressure, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_SUCCESS,
      payload: data,
    });
    yield delay(1500);
    if (action.payload.wf_for_pfd) {
      yield put({
        type: ActionTypes.WF_FOR_PDF,
        wf_for_pfd: action.payload.wf_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterPressureForConsumption(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getWaterFilterPressure, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_SUCCESS,
      payload: data,
    });
    yield delay(1500);
    if (action.payload.wf_for_pfd) {
      yield put({
        type: ActionTypes.WF_FOR_PDF,
        wf_for_pfd: action.payload.wf_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getWaterFilterBypass(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getWaterFilterBypass, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_BYPASS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getTotalConsumptionWaterFilter(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionWaterFilter, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* generateWaterFilterCsv(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.generateWaterFilterCsv, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put(showAlert('Generating file... ', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GENERATE_WATER_FILTER_CSV_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getWaterFilterCurrentBypass(api, action) {
  const { device_id, from } = action.payload;

  const response = yield call(api.getWaterFilterCurrentBypass, device_id, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getTotalConsumptionCartridgeWf(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionCartridgeWf, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getAllTotalConsumptionCartridgeWf(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getAllTotalConsumptionCartridgeWf, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* replaceWaterFilterCartridges(api, action) {
  const { payload } = action;
  const response = yield call(api.replaceWaterFilterCartridges, payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES_SUCCESS,
      payload: data,
    });
    yield put(showAlert('Cartridges Rplaced', { variant: 'success' }));

    const state = yield select();
    yield put(getLocationFunction(state.locations.item.id));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisCurrentState(api, action) {
  const response = yield call(api.getReverseOsmosisCurrentState, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* generateReverseOsmosisCsv(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.generateReverseOsmosisCsv, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put(showAlert('Generating file... ', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GENERATE_REVERSE_OSMOSIS_CSV_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getReverseOsmosisConsumption(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getReverseOsmosisConsumption, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION_SUCCESS,
      payload: data,
    });

    yield delay(1500);
    if (action.payload.ro_for_pfd) {
      yield put({
        type: ActionTypes.RO_FOR_PDF,
        ro_for_pfd: action.payload.ro_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getTotalConsumptionReverseOsmosis(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionReverseOsmosis, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisTds(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  action.payload.now = moment().format();
  const response = yield call(api.getReverseOsmosisTds, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_TDS_SUCCESS,
      payload: data,
    });
    yield delay(1500);

    if (action.payload.ro_for_pfd) {
      yield put({
        type: ActionTypes.RO_FOR_PDF,
        ro_for_pfd: action.payload.ro_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_TDS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisWaste(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getReverseOsmosisWaste, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_WASTE_SUCCESS,
      payload: data,
    });
    yield delay(1500);

    if (action.payload.ro_for_pfd) {
      yield put({
        type: ActionTypes.RO_FOR_PDF,
        ro_for_pfd: action.payload.ro_for_pfd,
      });
    }
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_WASTE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getConsumptionFromMembraneChangeRo(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionReverseOsmosis, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getTotalConsumptionCartridgeRo(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionCartridgeRo, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getAllTotalConsumptionCartridgeRo(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getAllTotalConsumptionCartridgeRo, action.payload);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* replaceReverseOsmosisCartridges(api, action) {
  const { payload } = action;
  const response = yield call(api.replaceReverseOsmosisCartridges, payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES_SUCCESS,
      payload: data,
    });
    yield put(showAlert('Cartridges Rplaced', { variant: 'success' }));

    const state = yield select();
    yield put(getLocationFunction(state.locations.item.id));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisBypass(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getReverseOsmosisBypass, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_BYPASS_FAILURE,
    });
    if (status !== 500) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisCurrentBypass(api, action) {
  const response = yield call(api.getReverseOsmosisCurrentBypass, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS_FAILURE,
    });
    if (status !== 500) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisCurrentPressure(api, action) {
  const { device_id: serialNumber, catridge_id, from } = action.payload;

  const response = yield call(api.getReverseOsmosisCurrentPressure, serialNumber, {
    catridge_id,
    from,
  });

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_FAILURE,
    });
    if (status !== 500) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getReverseOsmosisCurrentTemperature(api, action) {
  const { device_id: serialNumber, from } = action.payload;

  const response = yield call(api.getReverseOsmosisCurrentTemperature, serialNumber, { from });

  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_FAILURE,
    });
    if (status !== 500) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerCurrentState(api, action) {
  const { device_id: serialNumber, from } = action.payload;

  const response = yield call(api.getSoftenerCurrentState, serialNumber, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_STATE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_STATE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerConsumption(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getSoftenerConsumption, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_CONSUMPTION_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_CONSUMPTION_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerPressure(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getSoftenerPressure, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_PRESSURE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_PRESSURE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerBypass(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getSoftenerBypass, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_BYPASS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerWaste(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.getSoftenerWaste, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_WASTE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_WASTE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* generateSoftenerCsv(api, action) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  action.payload.tz = tz;
  const response = yield call(api.generateSoftenerCsv, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put(showAlert('Generating file... ', { variant: 'success' }));
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GENERATE_SOFTENER_CSV_FAILURE,
    });
    yield put(showAlert(errors.message, { variant: 'danger' }));
  }
}

export function* getConsumptionFromInstallSo(api, action) {
  const { payload } = action;
  payload.to = moment().toDate();
  const response = yield call(api.getTotalConsumptionSoftener, action.payload);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerCurrentPressure(api, action) {
  const { device_id, from } = action.payload;

  const response = yield call(api.getSoftenerCurrentPressure, device_id, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_PRESSURE_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_PRESSURE_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}

export function* getSoftenerCurrentBypass(api, action) {
  const { device_id, from } = action.payload;

  const response = yield call(api.getSoftenerCurrentBypass, device_id, { from });
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_BYPASS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_SOFTENER_CURRENT_BYPASS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
